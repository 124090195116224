import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
// OR using local (public folder)
    // -------------------------------------------------------
     const logo = (
        //  <Box
        //     component="img"
        //     src={ theme.palette.mode === 'light' ? "/logo/logo-ihapari-dark.png" : "/logo/logo-ihapari.svg"}
        //     sx={{ width: 160, height: 75, cursor: 'pointer', ...sx }}
        //  />
         <Box
            component="img"
            src={ theme.palette.mode === 'light' ? "/logo/simplex-light.svg" : "/logo/simplex-light.svg"}
            sx={{ width: 160, height: 75, cursor: 'pointer', ...sx,
              display: 'inline-flex'
             }}
         />
    );


    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
