// @mui
import MenuItem from '@mui/material/MenuItem';
// utils
// components
import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { IUser } from '../../types/user';
import { ITenant } from '../../types/tenant';
import { getCurrentCompany, isValidToken, setSession } from '../../auth/context/jwt/utils';
import axios, { endpoints } from '../../utils/axios';

// ----------------------------------------------------------------------


type Props = {
  loggedUser: IUser | undefined;
}


export default function CompanySwitch({ loggedUser }: Props) {

  const [company, setCompany] = React.useState('');
  const [hasManyLocations, setHasManyLocations] = React.useState(false);

  const handleChange = async (event: SelectChangeEvent) => {
    const tenantId = event.target.value as string;

    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

      const res = await axios
        .put(`${endpoints.auth.switchCompany}`,
          {headers: { Authorization: `Bearer ${accessToken}`}},{params: { tenantId}});

      await setSession(res.data);
      window.location.reload();
    }

  };

  useEffect(() => {
    const hasLocations = loggedUser !== undefined && loggedUser.businessLocations !== undefined && loggedUser.businessLocations.length > 1;
    setHasManyLocations(hasLocations);
    if (getCurrentCompany() && hasLocations) setCompany(getCurrentCompany);
  }, [loggedUser]);


  return (
    <>
      {hasManyLocations &&  <FormControl sx={{width:'200px'}}>
        <InputLabel id="company-select-label">Empresa</InputLabel>
        <Select
          size="small"
          labelId="company-select-label"
          id="company-select"
          value={company}
          label="Empresa"
          onChange={handleChange}
        >
          {loggedUser?.businessLocations?.map((n : ITenant) => (
            <MenuItem key={n.id} value={n.tenantId}>
              {n.tenantName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>}
    </>
  );
}
