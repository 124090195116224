import { createApi } from '@reduxjs/toolkit/query/react';
import { IAccount, IAccountDeleteResponse, IAccountPage } from '../../types/account';
import { customBaseQuery, isValidToken } from '../../auth/context/jwt/utils';
import axios, { endpoints } from '../../utils/axios';
import { IExtractPage, IReportExtract } from '../../types/extract';
import { saveAs } from 'file-saver';

const path: string = 'accounts'

type UpdateProps = {
    data: IAccount,
    id: number
}

export const accountService = createApi({
    reducerPath: 'accountService',
    tagTypes: ['accounts'],
    baseQuery: customBaseQuery,
    endpoints: (builder) => ({
        getAccounts: builder.query<IAccountPage, IAccountPage>({
            query: (page : IAccountPage) => ({
                url: `/${path}/list`,
                params: { ...page, content: [] }
            }),
            providesTags: ['accounts']
        }),
        getExtracts: builder.query<IReportExtract, IExtractPage>({
            query: (page : IExtractPage) => ({
                url: `/${path}/generateExtract`,
                params: { ...page, content: [] }
            }),
            providesTags: ['accounts']
        }),
        getAccount: builder.mutation<IAccount, number>({
            query: (id: number) => ({
                url: `/${path}/findById/${id}`,
                method: 'GET'
            })
        }),
        saveAccount: builder.mutation<IAccount, IAccount>({
            query: (account: IAccount) => ({
                url: `/${path}/insert`,
                method: 'POST',
                body: account,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['accounts'],
        }),
        updateAccount: builder.mutation<IAccount, Partial<UpdateProps>>({
            query: (data: UpdateProps) => ({
                url: `/${path}/update`,
                method: 'PUT',
                body: data,
                headers: {'Content-Type': 'multipart/form-data'}
            }),
            invalidatesTags: ['accounts']
        }),
        deleteAccount: builder.mutation<IAccountDeleteResponse, number>({
            query: (id: number) => ({
                url: `/${path}/deleteById/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['accounts']
        })
    }),
})

export const {
    useGetAccountsQuery,
    useGetExtractsQuery,
    useSaveAccountMutation,
    useDeleteAccountMutation,
    useGetAccountMutation,
    useUpdateAccountMutation,
} = accountService

type Range = {
    startDate: string,
    endDate: string
}

type PropsDashboard = {
    range: Range,
    includeClosing: boolean,
}

export const generateDashboard = async ({ range, includeClosing } : PropsDashboard) => {
    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

        const res = await axios
            .get(`${endpoints.common.dashboard}/generate`,
                {
                    headers: { Authorization: `Bearer ${accessToken}`},
                    params: {
                        startDate: range.startDate,
                        endDate: range.endDate,
                        includeClosing
                    }
                });

        return res.data;
    }
}

type Props = {
    startDate: string,
    endDate: string,
    filterOption: string,
    includeClosing?: boolean,
}

export const generateBalancete = async ({ filterOption, startDate, endDate, includeClosing } : Props) => {
    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

        const res = await axios
            .get(`${endpoints.common.balancete}/generate`,
                {
                    headers: { Authorization: `Bearer ${accessToken}`},
                    params: {
                        filterOption,
                        includeClosing,
                        startDate,
                        endDate
                    }
                });

        return res.data;
    }
    return null;
}

export const generateAccountCode = async (parentId : number) => {
    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

        const res = await axios
            .get(`${endpoints.common.accounts}/generateCode/${parentId}`,
                {headers: { Authorization: `Bearer ${accessToken}`}});

        return res.data;
    }
    return "";
}

export const exportExtractToExcel = async (accountId : number | null, startDate : string, endDate : string) => {

    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {

        try {
            const response = await axios
                .get(`${endpoints.common.accounts}/extractExport`,
                    {
                        headers: {Authorization: `Bearer ${accessToken}`},
                        params: {
                            accountId, // Replace with actual account ID
                            startDate, // Replace with actual start date
                            endDate,
                        },
                        responseType: 'blob',
                    },);

            const contentDisposition = response.headers['content-disposition'];
            const fileName = contentDisposition
                ? contentDisposition.split('filename=')[1]
                : 'journal-report.xlsx';

            // Save the file using FileSaver.js
            saveAs(new Blob([response.data]), fileName);
        } catch (error) {
            console.error('Error exporting report:', error);
        }
    }
}

