import {m} from 'framer-motion';
// @mui
import {SxProps, Theme} from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
// assets
import {ForbiddenIllustration} from 'src/assets/illustrations';
// components
import {MotionContainer, varBounce} from 'src/components/animate';
import {useCallback, useEffect, useState} from "react";
import {hasPermission, isValidToken, jwtDecode, setSession} from "../context/jwt/utils";
import {useAuthContext} from "../hooks";
import {useRouter} from "../../routes/hook";
import SetPasswordPage from "../../pages/auth/jwt/set-password";
import {paths} from "../../routes/paths";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: string[];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const loginPaths: Record<string, string> = {
    jwt: paths.auth.jwt.login,
};

export default function RoleBasedGuard({ hasContent, roles, children, sx }: RoleBasedGuardProp) {

    const router = useRouter();

    let authenticated = false;
    let hasNoChangedPassword = false;
    // Pegar a token

    const accessToken = sessionStorage.getItem("accessToken");

    if (accessToken && isValidToken(accessToken)) {
        authenticated = true;
    }

    const passwordChangedAt = sessionStorage.getItem("hasNoChangedPassword");
    if (passwordChangedAt) {
        hasNoChangedPassword = true;
    }
    //

    const { method } = useAuthContext();

    const [checked, setChecked] = useState(false);
    const check = useCallback(() => {
        if (!authenticated) {
            const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString();

            const loginPath = loginPaths[method];

            const href = `${loginPath}?${searchParams}`;

            router.replace(href);
        } else {
            setChecked(true);
        }
    }, [authenticated, method, router]);

    useEffect(() => {
        check();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!checked) {
        return null;
    }

    if (hasNoChangedPassword) {
        return <SetPasswordPage />;
    }

    const { authorities } = jwtDecode(accessToken);

  if (typeof roles !== 'undefined' && !hasPermission(authorities, roles)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permissão negada
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
              Não possui permissão para aceder a este recurso.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
