import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
//
import { Suspense } from 'react';
import {authRoutes} from './auth';
import {dashboardRoutes} from './dashboard';
import {HomePage, mainRoutes} from "./main";
import {customerRoutes} from "./customer";
import {accountRoutes} from "./account";
import {journalRoutes} from "./journal";
import {extractRoutes} from "./extract";
import {companyRoutes} from "./company";
import { AuthGuard } from '../../auth/guard';
import DashboardLayout from '../../layouts/dashboard';
import { LoadingScreen } from '../../components/loading-screen';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([

    // ----------------------------------------------------------------------
    // Dashboard routes
    ...dashboardRoutes,

    // Auth routes
    ...authRoutes,
    
    // Customer routes
    ...customerRoutes,

    // Main routes
    ...mainRoutes,

    ...accountRoutes,

      ...journalRoutes,

      ...extractRoutes,

      ...companyRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
